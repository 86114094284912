import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@womcorp/wom-ui-kit';
import SEO from '../components/seo';
import { formatRut } from '../utils/formatters';
import { AuthService } from '../services';
import { InOutCardResponsive } from '../components';
import { ReactComponent as Ghost } from '../assets/images/ghost.svg';
import { LoginLayout } from '../components/Layouts';
import { retrieveFromStorageAsObject } from '../utils/storage/localStorage';

const NoAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;

  h1,
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

const CustomCardWrapper = styled(InOutCardResponsive)`
  max-width: 470px;
`;

// TODO: fix this. Is an issue passing the ref to the component
const NoAccountCenteredText = styled(Text)`
  text-align: center;
`;

const GhostImgWrapper = styled.div`
  margin: 30px;
  text-align: center;
`;

const CustomButtonWrapper = styled.div`
  width: 85%;
  margin: 0 auto;
`;

const LinkWrapper = styled.div`
  a {
    color: white;
  }
`;

const RutWrapper = styled.span`
  font-weight: bold;
`;

const RutError = () => {
  const enteredRutNumber = AuthService.getRutNumber();
  const formattedRutNumber = formatRut(enteredRutNumber);

  const textContent = () => {
    const serviceFail = !!retrieveFromStorageAsObject(
      AuthService.authLocalStorageKeys.rutServiceFail
    );
    let content;

    if (serviceFail) {
      content =
        'Nuestro sistema ha fallado. Reintenta en los próximos minutos';
    } else {
      content = (
        <span>
          Hemos detectado que el RUT{' '}
          <RutWrapper>{formattedRutNumber}</RutWrapper> no está asociado a una
          cuenta fibra.
        </span>
      );
    }

    return content;
  };

  return (
    <>
      <SEO title="Login" />
      <LoginLayout>
        <NoAccountWrapper>
          <CustomCardWrapper>
            <GhostImgWrapper>
              <Ghost />
            </GhostImgWrapper>
            <NoAccountCenteredText>{textContent()}</NoAccountCenteredText>
            <CustomButtonWrapper>
              <a href="https://www.wom.cl" target="_blank" rel="noreferrer">
                <Button variant="primary" size="l" full>
                  <LinkWrapper>IR A WOM.CL</LinkWrapper>
                </Button>
              </a>
            </CustomButtonWrapper>
          </CustomCardWrapper>
        </NoAccountWrapper>
      </LoginLayout>
    </>
  );
};

export default RutError;
